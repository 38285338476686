import {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from 'react';
import { local } from '@/pages/api/api';
import { OptionalType } from '@/types';

export type SettingsProviderProps = PropsWithChildren & {};

export type SettingsProviderContext = {
  creditCardDiscount: number;
  pixDiscount: number;
  setCreditCardDiscount: (discount: number) => void;
  setPixDiscount: (discount: number) => void;
  pagamentoPadrao: string;
  setPagamentoPadrao?: (pagamento: string) => void;
  isReady: boolean;
  specialProtection?: OptionalType;
  basicProtection?: OptionalType;
  vantagens: {
    image_suaViagem_desktop?: any,
    image_suaViagem_mobile?: any,
    VantagensFoco: {
      Icon: any,
      text: string
    }[],
    VantagensFoco_Text: string
  }
}

const Context = createContext<SettingsProviderContext>({} as SettingsProviderContext);

export function SettingsProvider({ children }: SettingsProviderProps) {
  const [creditCardDiscount, setCreditCardDiscount] = useState<number>(0);
  const [pixDiscount, setPixDiscount] = useState<number>(0);
  const [pagamentoPadrao, setPagamentoPadrao] = useState<string>('');
  const [isReady, setIsReady] = useState(false);
  const [specialProtection, setSpecialProtection] = useState();
  const [basicProtection, setBasicProtection] = useState();
  const [vantagens, setVantagens] = useState({
    image_suaViagem_desktop: undefined,
    image_suaViagem_mobile: undefined,
    VantagensFoco: [],
    VantagensFoco_Text: ''
  })

  useEffect(() => {
    async function fetchSettings() {
      try {
        const {
          data: { credit_card_discount, pix_discount, PagamentoPadrao, specialProtection, basicProtection, ...rest },
        } = await local('/api/settings');

        setCreditCardDiscount(credit_card_discount);
        setPixDiscount(pix_discount);
        setPagamentoPadrao(PagamentoPadrao);
        setIsReady(true);
        setSpecialProtection(specialProtection)
        setBasicProtection(basicProtection)
        setVantagens({
          image_suaViagem_desktop: rest.image_suaViagem_desktop,
          image_suaViagem_mobile: rest.image_suaViagem_mobile,
          VantagensFoco: rest.VantagensFoco,
          VantagensFoco_Text: rest.VantagensFoco_Text
        })
      } catch (err) {
        console.log('settings fetch err', `${err}`);
      }
    }

    fetchSettings();
  }, []);

  return (
    <Context.Provider
      value={{
        creditCardDiscount,
        pixDiscount,
        setCreditCardDiscount,
        setPixDiscount,
        pagamentoPadrao,
        setPagamentoPadrao,
        isReady,
        specialProtection,
        basicProtection,
        vantagens
      }}
    >
      {children}
    </Context.Provider>
  );
}

export function useSettings() {
  return useContext(Context);
}
